@import url("https://fonts.googleapis.com/css2?family=Geo:ital@0;1&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Geo", sans-serif;
}

.app {
  background: #211232;
  position: relative;
}
.app .logo {
  background-color: #FFE97C;
}
.app .logo img {
  width: 150px;
  display: block;
  margin: 0 auto;
  padding: 2rem 0;
}
.app .navbar {
  display: flex;
  justify-content: space-around;
  background: #372553;
  padding: 0.7rem 20%;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.app .navbar a {
  color: #A16FE9;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  font-size: 1.5rem;
}
.app .navbar a:hover {
  background-color: #A16FE9;
  color: #372553;
}
.app .navbar .active {
  background: #FE1675;
  border-radius: 5px;
  padding: 0.2rem 0.4rem;
  color: #372553;
  text-transform: uppercase;
}
.app .navbar .btn {
  background-color: #A16FE9;
  padding: 0.2rem 0.2rem;
  border-radius: 5px;
  color: #FFE97C;
}
.app .navbar > * {
  font-weight: 500;
  cursor: pointer;
}
.app .navbar > * :active {
  scale: 0.8;
}
.app .footer {
  background: #FFE97C;
  padding: 4rem 0;
}
.app .footer .container {
  width: 80%;
  margin: 0 auto;
}
.app .footer .container .logoLinks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.app .footer .container .logoLinks .logo {
  width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.app .footer .container .logoLinks .links {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.app .footer .container .logoLinks .links > * {
  color: #FE1675;
  text-decoration: none;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}
.app .footer .container .logoLinks > * {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.app .footer .container .trademark {
  color: #FE1675;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-top: 3rem;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

@media (max-width: 700px) {
  .app .navbar {
    padding: 0.5rem 0;
  }
  .app .navbar a {
    font-size: 1rem;
  }
  .app .home .mintSection .bg {
    padding: 2rem 0;
  }
  .app .home .mintSection .bg .left,
.app .home .mintSection .bg .right {
    margin: -1rem 0;
    width: 150px;
  }
  .app .footer .container .logoLinks {
    flex-direction: column;
    row-gap: 3rem;
  }
}
.home .hide {
  display: none;
}
.home .scroll {
  width: 90%;
  max-width: 800px;
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home .scroll .container {
  width: 100%;
  position: absolute;
  z-index: 4;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 8% 10%;
  row-gap: 1.5rem;
  align-items: center;
}
.home .scroll .container .congrates {
  font-size: 2.5rem;
  font-weight: 600;
}
.home .scroll .container .received {
  font-weight: 600;
}
.home .scroll .container a {
  text-decoration: none;
  color: white;
  background-color: #FE1675;
  border-radius: 20px;
  padding: 0.5rem 0.8rem;
}
.home .scroll .container .cross {
  width: 10%;
  align-self: flex-end;
  cursor: pointer;
}
.home .scroll .bg {
  width: 100%;
  z-index: -1;
}
.home .mintSection {
  position: relative;
}
.home .mintSection .bg {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: inherit;
}
.home .mintSection .bg .left,
.home .mintSection .bg .right {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 300px;
  margin: -3rem 0;
}
.home .mintSection .bg .left {
  transform: translateX(-50%);
}
.home .mintSection .bg .right {
  left: 100%;
  transform: translateX(-50%);
}
.home .mintSection .bg .bottom {
  z-index: 2;
}
.home .mintSection .hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  width: 60%;
  position: relative;
  padding: 4rem 0;
  max-width: 500px;
}
.home .mintSection .hero .sun {
  position: absolute;
  width: 80%;
}
.home .mintSection .hero .title {
  width: 100%;
}
.home .mintSection .hero .game {
  width: 50%;
}
.home .mintSection .hero .v {
  width: 200px;
}
.home .mintSection .hero .premint {
  width: 100%;
  z-index: 3;
  margin-top: 20%;
  max-width: 300px;
  margin-bottom: -15%;
}
.home .mintSection .hero p {
  font-size: 1.5rem;
}
.home .mintSection .hero .video {
  position: relative;
  width: 60%;
  margin-top: 1rem;
}
.home .mintSection .hero .video .message {
  font-size: 2rem;
}
.home .mintSection .hero .video .counter {
  display: flex;
  margin: 0 auto;
  margin-top: 1rem;
  justify-content: space-evenly;
}
.home .mintSection .hero .video .counter .value {
  font-size: 2rem;
}
.home .mintSection .hero .video .counter button {
  font-size: 2rem;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #FFE97C;
  background: #A16FE9;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s;
}
.home .mintSection .hero .video .counter button:active {
  transform: rotateZ(180deg);
}
.home .mintSection .hero .video .gp {
  width: 100%;
  z-index: 2;
}
.home .mintSection .hero .video video::-webkit-media-controls {
  display: none;
}
.home .mintSection .hero .video .collect,
.home .mintSection .hero .video .hide,
.home .mintSection .hero .video .mint,
.home .mintSection .hero .video .skip {
  cursor: pointer;
  font-weight: 600;
  font-size: 1.5rem;
}
.home .mintSection .hero .video .hide {
  display: none;
}
.home .mintSection .hero .video .skip {
  display: block;
  background: #FFE97C;
  color: #211232;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
}
.home .mintSection .hero .video .mint {
  display: block;
  text-transform: uppercase;
  background: #FE1675;
  color: #211232;
  padding: 0.3rem 1rem;
  border-radius: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
  font-weight: 600;
  margin-top: 1rem;
}
.home .mintSection .hero .video .mint:active {
  scale: 0.9;
  background: #A16FE9;
  color: white;
}
.home .mintSection .hero .video .blank {
  width: 75%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 27%;
  border-radius: 30%;
}
.home .mintSection .hero .video .collect {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
  z-index: 2;
}
.home .mintSection .hero .video .collect h3 {
  background: #372553;
  color: white;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.home .mintSection .hero .video .collect .message {
  margin-top: 1rem;
  background: #A16FE9;
  width: 300px;
  padding: 0.5rem;
  border-radius: 5px;
}
.home .minted {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem;
  border: 2px solid #FE1675;
  border-radius: 10px;
  margin-top: 1rem;
}
.home .collection_LB {
  padding: 2rem 0;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.home .collection_LB .sidetext {
  display: flex;
  width: 100%;
  z-index: 1;
  justify-content: space-between;
  position: absolute;
}
.home .collection_LB .sidetext img {
  width: 35%;
}
.home .collection_LB .bg {
  font-size: 3rem;
  color: #372553;
  position: absolute;
  background: white;
  width: 100%;
  top: 50%;
}
.home .collection_LB .bg .right,
.home .collection_LB .bg .left {
  width: 100%;
  background-color: #B9ABDE;
  text-align: center;
}
.home .collection_LB .bg .right {
  position: absolute;
  left: 50%;
  transform: translateX(-2rem);
}
.home .collection_LB .bg .right .container {
  transform: rotateZ(90deg);
  background-color: aqua;
}
.home .collection_LB .bg .left {
  position: absolute;
  left: -50%;
  transform: translateY(50%);
}
.home .collection_LB .bg .left .container {
  background-color: aqua;
  transform: rotateZ(-90deg);
}
.home .collection_LB .collection {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  margin-bottom: 4rem;
  z-index: 2;
}
.home .collection_LB .collection .heading {
  color: white;
  font-size: 2rem;
}
.home .collection_LB .collection .nfts {
  display: flex;
  -moz-column-gap: 4%;
       column-gap: 4%;
  justify-content: center;
}
.home .collection_LB .collection .nfts > * {
  width: 20%;
  z-index: 1;
}
.home .collection_LB .LB {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  z-index: 2;
}
.home .collection_LB .LB .heading {
  color: white;
  font-size: 2rem;
}
.home .collection_LB .LB .legends {
  padding: 0 2rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.home .collection_LB .LB .legends .legend {
  width: 20%;
  z-index: 1;
}
.home .collection_LB p {
  color: #B9ABDE;
  max-width: 500px;
  text-align: center;
  width: 85%;
  font-size: 1.2rem;
}
.home .loreLoot {
  background: #FFE97C;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}
.home .loreLoot .chatbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.home .loreLoot .chatbox img {
  width: 90%;
}
.home .loreLoot .chatbox .nar2 {
  align-self: flex-end;
}
.home .loreLoot .heading {
  color: #372553;
  font-size: 2rem;
  width: 100%;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  font-weight: 600;
}
.home .loreLoot .heading span {
  font-family: Roboto;
  font-size: 6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.home .loreLoot .perks {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 10%;
  -moz-column-gap: 5%;
       column-gap: 5%;
}
.home .loreLoot .perks .perk {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 1rem;
}
.home .loreLoot .perks .perk img {
  width: 100%;
}
.home .loreLoot .perks .perk p {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
}
.home .loreLoot .cloud {
  width: 35%;
  position: relative;
  margin-top: 1rem;
  left: -5%;
}
.home .roadmap {
  background-image: url("../Assets/Roadmapbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.home .roadmap .bgclr {
  background-color: #211232;
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0.93;
}
.home .roadmap .map {
  width: 95%;
  padding: 10% 0;
  margin: 0 auto;
  max-width: 900px;
  z-index: 1;
}
.home .roadmap .cloud {
  width: 35%;
  position: absolute;
  padding: 0;
  align-self: flex-end;
  right: -5%;
  bottom: 0;
}
.home .team {
  padding: 6rem 5%;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}
.home .team .heading {
  font-size: 3rem;
  width: 100%;
  text-align: center;
  color: white;
}
.home .team .members {
  display: flex;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.home .team .members .member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  row-gap: 1rem;
  text-align: center;
  font-size: 1.5rem;
  text-decoration: none;
}
.home .team .members .member .diamond {
  width: 25%;
  margin-bottom: -1rem;
}
.home .team .members .member .memnft {
  width: 100%;
}
.home .partners {
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}
.home .partners .heading {
  width: 100%;
  color: white;
  font-size: 4rem;
  text-align: center;
}
.home .partners .logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  row-gap: 1rem;
}
.home .partners .logos img {
  width: 15%;
  max-width: 600px;
}
.home .partners p {
  color: #FFE97C;
  font-size: 1rem;
  margin: 0 auto;
  width: 70%;
  max-width: 700px;
  text-align: center;
  font-size: 3rem;
}
.home .partners p a {
  color: #FFE97C;
}

@media (max-width: 700px) {
  .home .scroll .container {
    font-size: 1rem;
    row-gap: 0.3rem;
  }
  .home .scroll .container .congrates {
    font-size: 1.2rem;
  }
  .home .mintSection .bg {
    padding: 2rem 0;
  }
  .home .mintSection .bg .left,
.home .mintSection .bg .right {
    margin: -1rem 0;
    width: 150px;
  }
  .home .mintSection .hero p {
    font-size: 1rem;
  }
  .home .mintSection .hero .video {
    width: 100%;
  }
  .home .mintSection .hero .video .collect,
.home .mintSection .hero .video .hide,
.home .mintSection .hero .video .mint,
.home .mintSection .hero .video .skip {
    font-size: 0.9rem;
    font-weight: 600;
  }
  .home .mintSection .hero .video .counter .value {
    font-size: 1.5rem;
  }
  .home .mintSection .hero .video .counter button {
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
  }
  .home .minted {
    font-size: 1rem;
  }
  .home .collection_LB .collection .heading {
    font-size: 2rem;
  }
  .home .collection_LB .LB .heading {
    font-size: 1.5rem;
  }
  .home .collection_LB .LB .legends {
    padding: 0 0.5rem;
  }
  .home .collection_LB p {
    font-size: 1rem;
  }
  .home .loreLoot {
    padding: 0rem 0.2rem;
    padding-top: 2rem;
  }
  .home .loreLoot .heading p {
    font-size: 1.5rem;
  }
  .home .loreLoot .heading span {
    font-size: 3rem;
  }
  .home .loreLoot .perks {
    padding: 0;
    row-gap: 1rem;
    flex-direction: column;
    align-items: center;
  }
  .home .loreLoot .perks .perk {
    width: 70%;
  }
  .home .loreLoot .perks .perk img {
    width: 50%;
  }
  .home .loreLoot .perks .perk p {
    font-size: 1.2rem;
  }
  .home .team {
    padding: 2rem 0;
  }
  .home .team .heading {
    font-size: 2rem;
  }
  .home .team .members {
    -moz-column-gap: 0;
         column-gap: 0;
  }
  .home .team .members .member {
    font-size: 0.7rem;
    row-gap: 0.5rem;
  }
  .home .team .members .member .diamond {
    margin-bottom: 0;
  }
  .home .partners .heading {
    font-size: 2rem;
  }
  .home .partners .logos img {
    width: 30%;
  }
  .home .partners p {
    font-size: 1.5rem;
  }
}